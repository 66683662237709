.navigation-bar {
  background-color: #b0041d;
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  user-select: none;
}

.navigation-bar-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navigation-bar-list-item {
  padding: 10px;
  width: 180px;
  text-align: center;
  cursor: pointer;
}

.navigation-bar-label {
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #b0041d;
}

.navigation-bar-list-item:hover .navigation-bar-label {
  font-weight: 1000;
  color: #fff5f1;
}

.logo {
  height: 90px;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .navigation-bar-list {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .navigation-bar-list-item {
    padding: 15px;
    width: 260px;
  }

  .logo {
    height: 150px;
  }
}
