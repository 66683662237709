.vaults-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vaults-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 630px;
}

.vaults-list-item {
  padding: 20px 20px 40px 20px;
}

.vaults-image {
  width: 100%;
  height: auto;
  border: 5px outset #d1e7e0;
}

.vaults-image-vertical {
  width: 60%;
}

.vaults-list-item-vertical {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.vaults-list-item-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vaults-image-text-horizontal {
  margin-left: 1%;;
}

.vaults-image-text {
  padding-top: 10px;
}

.vaults-image-text-vertical {
  width: 30%;
}

.vaults-image-text-title {
  margin: 0;
  font-weight: 700;
}

.vaults-image-text-not-title {
  margin: 0;
}