.contact-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  background-color: #fff5f1;
  border-radius: 15px;
}

.contact-content-list {
  padding: 30px 20px 20px 20px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 630px;
}

.contact-content-list-item {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.contact-content-list-item-input-text {
  height: 36px;
}

.contact-content-list-item-text-area {
  height: 146px;
  padding-bottom: 30px;
}

.contact-content-title {
  margin: 0;
  padding: 10px;
}

.contact-content-text {
  border-radius: 6px;
  border:  1px solid #ebebe4;
  transition: border 0.15s ease-out;
  outline: none;
  width: 300px;
  padding: 8px;
  background-color: #fff5f1;
}

.contact-content-text:hover {
  border: 1px solid  black;
}

.contact-content-text:focus {
  border: 2px solid  #b0041d;
}

.contact-content-text-area {
  height: 128px;
}

.contact-content-button {
  border-radius: 6px;
  border: none;
  padding: 8px;
  background-color: #b0041d;
  cursor: pointer;
  width: 100px;
  font-weight: bold;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

}

.contact-content-button:hover {
  color: white;
}

.instagram-logo-container {
  width: 40px;
  height: 40px;
  background-color: #b0041d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 30px;
}

.instagram-logo-container:hover .instagram-logo {
  fill: white;
}

.instagram-logo-container {
  cursor: pointer;
}


