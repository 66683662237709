.page-button-image {
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.page-button-image:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transform: scale(1.05);
}
