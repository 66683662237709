* {
    font-family: 'Noto Sans', monospace;
    -webkit-tap-highlight-color: transparent;
}

html {
    overflow-y: scroll;
}

body {
    background-color: #b0041d;
}