.art-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
}

.art-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  background-color: #fff5f1;
  border-radius: 15px;
}

.art-content-list {
  padding: 40px 20px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 630px;
}

.art-content-list-item {
  margin-bottom: 40px;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.art-content-image {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.art-content-image-vertical {
  width: 60%;
}

.art-content-list-item-vertical {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.art-content-list-item-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.art-content-image-text-horizontal {
  margin-left: 1%;
  padding-bottom: 10px;
}

.art-content-image-text-vertical {
  padding-right: 10px;
}

.art-content-image-text {
  padding: 10px;
}

.art-content-image-text-vertical {
  width: 30%;
}

.art-content-image-text-title {
  margin: 0;
  font-weight: 700;
}

.art-content-image-text-not-title {
  margin: 0;
}
