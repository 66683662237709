.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  background-color: #fff5f1;
  border-radius: 15px;
  margin-bottom: 10px;
}

.about-content-list {
  padding: 40px 20px;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin-bottom: -10px;
}

.about-content-list-item {
  padding-bottom: 20px;
  width: 100%;
}

.about-content-profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-content-profile-image {
  border-radius: 50%;
  height: 240px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  user-select: none;
}

.about-content-recommandation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.about-content-recommandation-text {
  font-style: italic;
  padding: 0;
  margin: 0;
}

.about-content-recommandation-author {
  padding: 0;
  margin: 0;
}

.education-title {
  font-size: 24px;
  color: #bc827a;
}

.education-list {
  margin-top: 8px;
  list-style-type: disc;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.projects-title {
  font-size: 24px;
  color: #bc827a;
}

.projects-list {
  margin-top: 8px;
  list-style-type: disc;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.recommandations-title {
  font-size: 24px;
  color: #bc827a;
}

.recommandations-list {
  list-style: none;
  padding: 0;
  margin-top: 8px;
}

.recommandations-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 20px;
}
