.scroll-to-top-button-container {
  position: fixed;
  bottom: 5%;
  right: 5%;
}

.scroll-to-top-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #b0041d;
  border: 1px solid black;
  cursor: pointer;
}

.scroll-to-top-button:hover .scroll-to-top-button-image {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

@media screen and (max-width: 640px) {
  .scroll-to-top-button-container {
    bottom: 5%;
    right: 5%;
  }
}
