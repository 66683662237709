.dropdown-content {
  padding: 0;
  margin: 2px 0px 0px 0px;
  list-style: none;
  background-color: #b0041d;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.dropdown-list {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #b0041d;
  display: none;
  flex-direction: column;
  z-index: 1;
}

.dropdown-list-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-label {
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}

.dropdown-list-item:hover .dropdown-label {
  color: #fff5f1;
}

@media screen and (max-width: 720px) {
  .dropdown-content {
    max-width: 260px;
  }
}