.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
  background-color: #fff5f1;
  border-radius: 15px;
  margin-bottom: 10px;
}

.pages-list {
  padding: 30px 0px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
}

.pages-list-item {
  padding: 20px 40px;
}
